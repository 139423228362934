import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout.amp"
import SEO from "../components/seo"

export default function Template({
  data, path // this prop will be injected by the GraphQL query below.
}) {
  const { word, similar, similarDef } = data;

  function linkUp(text){
    return text.replace(/'/g, `"`).split(`<a href="`).map((splitted, i) => {
      return splitted.split(`</a>`).map((part, j) => {
        if(part.includes("/kelime/")){
          let wordSlug = part.split(">")[1];
          if(wordSlug[wordSlug.length-1] == "."){
            wordSlug = wordSlug.substr(0, wordSlug.length - 1);
          }
          return <Link key={`${i}-${j}`} to={`/kelime/${wordSlug}`}>{wordSlug}</Link>;
        }else{
          return <span key={`${i}-${j}`} dangerouslySetInnerHTML={{__html: part}}></span>;
        }
      })
    });
  }

  const renderWord = (word) => {
    if(word.endsWith(1)){
      return word.substr(0, word.length - 1);
    }else if(word.endsWith(2)){
      return (<>{word.substr(0, word.length - 1)}<span>(Yan anlam)</span></>);
    }else if(word.endsWith(3)){
      return (<>{word.substr(0, word.length - 1)}<span>(3)</span></>);
    }else{
      return word;
    }
  }

  let title = renderWord(word.kelime ? word.kelime[0].toLocaleUpperCase("TR") + word.kelime.substr(1, word.kelime.length - 1): "");
  let titleStr = word.kelime ? word.kelime[0].toLocaleUpperCase("TR") + word.kelime.substr(1, word.kelime.length - 1) : "";

  let tags = word.tanim.split(`title="`).map(tag => tag.includes(`"`) ? tag.split(`"`)[0].split(" (")[0] : null).filter(tag => tag != null && (!tag.includes("<") && !tag.includes("Daha") && !tag.includes(":") && tag != ""));
  tags = tags.filter((value, index, self) => {
      return self.indexOf(value) === index;
  });


  const regex = /onclick=("[^"]*"|'[^']*'|[^'">])*/gi;
  word.tanim = word.tanim.replace(regex, '').replace(/xml:lang="(.\w)"/g, "").replace(/lang="(.\w)"/g, "");
  word.koken = word.koken.replace(regex, '').replace(/xml:lang="(.\w)"/g, "").replace(/lang="(.\w)"/g, "");
  word.ekaciklama = word.ekaciklama
  .replace(/<\w>/g, "<b>")
  .replace(/<\/\w>/g, "</b>")
  .replace(/<kaktüs>/g, "<b>kaktüs</b>")
  .replace(/xml:lang="(.\w)"/g, "").replace(/lang="(.\w)"/g, "");

  return (
    <Layout>
      <h1>{title}</h1>
      <p>{linkUp(word.tanim)}</p>

      <amp-ad width="100vw" height="320" style={{position: "relative", left: -10}}
           type="adsense"
           data-ad-client="ca-pub-3602356900147773"
           data-ad-slot="2269797312"
           data-auto-format="rspv"
           data-full-width="">
        <div overflow=""></div>
      </amp-ad>

      <h3>Tarihte En Eski Kaynak</h3>
      <p dangerouslySetInnerHTML={{__html: word.tarihce}}></p>
      <p className="box"><b>Önemli Not:</b> Bu kaynak kayıtlara geçmiş ve bu kelimenin kullanıldığı yazılı ilk kaynaktır. Kullanımı daha öncesinde sözlü olarak veya günlük hayatta yaygın olabilir.</p>
      <h3>Kelime Kökeni</h3>
      <p>{linkUp(word.koken)}</p>

      <amp-ad width="100vw" height="320" style={{position: "relative", left: -10}}
           type="adsense"
           data-ad-client="ca-pub-3602356900147773"
           data-ad-slot="2269797312"
           data-auto-format="rspv"
           data-full-width="">
        <div overflow=""></div>
      </amp-ad>

      {word.ekaciklama &&
        <>
          <h3>Ek Bilgi</h3>
          {linkUp(word.ekaciklama)}
        </>
      }
      {word.telaffuz &&
        <>
          <h3>Kelime Telaffuzu</h3>
          <p>{title} kelimesiniz telaffuz edilişini Telaffuz sözlüğünden dinleyebilirsiniz, {title} nasıl telaffuz edilir? Öğrenmek ve dinlemek için oynat ikonuna basın.</p>
          <div className="audioCarrier">
            <amp-audio
              width="400"
              height="50"
              preload="none" controls={true}
              src={`https://api.etimolojiturkce.com/telaffuz.php?perma=${word.telaffuz}`}
            >
              <div fallback>
                <p>Your browser doesn’t support HTML5 audio</p>
              </div>
              <source type="audio/mpeg" src="foo.mp3" />
            </amp-audio>
            <p className="saglanmaktadir">Bu kelime telaffuzunu <a href="http://trttelaffuz.com" target="_blank" rel="nofollow">TRT Telaffuz sözlüğü</a> sağlamaktadır.</p>
          </div>
        </>
      }
      {similar.edges.concat(similarDef.edges).length > 1 &&
        <>
          <h3>Benzer Kelimeler</h3>
          <ul className="similarList">
            {similar.edges.concat(similarDef.edges).map((sim, i) => {
              if(sim.node.kelime != word.kelime){
                return (<li key={i}><Link to={`/kelime/${sim.node.kelime}`}>{sim.node.kelime}</Link></li>)
              }
            })}
          </ul>
        </>
      }

      {tags.length &&
        <p className="box">
          <strong>Etiketler: </strong>
          {tags.map((tag, i) => <span key={i}><Link to={`/kategori/${tag.replace(/ /g, "-")}`}>{tag}</Link>{i != tags.length-1 ? ",": ""} </span>)}
          kategori veya etiketlerine bağlıdır. <a href={`https://turkcenedemek.com/kelime/${word.kelime}/`} target="_blank">{title} ne demek?</a>
        </p>
      }

      <amp-ad width="100vw" height="320" style={{position: "relative", left: -10}}
           type="adsense"
           data-ad-client="ca-pub-3602356900147773"
           data-ad-slot="2269797312"
           data-auto-format="rspv"
           data-full-width="">
        <div overflow=""></div>
      </amp-ad>

      <div className="navigation">
        <Link to={`/kelime/${word.prev}`}><div className="navButton"><svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><g data-name="arrow-back"><rect width="24" height="24" transform="rotate(90 12 12)" opacity="0"/><path d="M19 11H7.14l3.63-4.36a1 1 0 1 0-1.54-1.28l-5 6a1.19 1.19 0 0 0-.09.15c0 .05 0 .08-.07.13A1 1 0 0 0 4 12a1 1 0 0 0 .07.36c0 .05 0 .08.07.13a1.19 1.19 0 0 0 .09.15l5 6A1 1 0 0 0 10 19a1 1 0 0 0 .64-.23 1 1 0 0 0 .13-1.41L7.14 13H19a1 1 0 0 0 0-2z"/></g></g></svg> Önceki</div></Link>
        <Link to={`/kelime/${word.random}`}><div className="navButton" style={{backgroundColor: "#6499be", color: "#fff"}}>
<svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><g data-name="shuffle-2"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"/><path fill="#fff" d="M18.71 14.29a1 1 0 0 0-1.42 1.42l.29.29H16a4 4 0 0 1 0-8h1.59l-.3.29a1 1 0 0 0 0 1.42A1 1 0 0 0 18 10a1 1 0 0 0 .71-.29l2-2A1 1 0 0 0 21 7a1 1 0 0 0-.29-.71l-2-2a1 1 0 0 0-1.42 1.42l.29.29H16a6 6 0 0 0-5 2.69A6 6 0 0 0 6 6H4a1 1 0 0 0 0 2h2a4 4 0 0 1 0 8H4a1 1 0 0 0 0 2h2a6 6 0 0 0 5-2.69A6 6 0 0 0 16 18h1.59l-.3.29a1 1 0 0 0 0 1.42A1 1 0 0 0 18 20a1 1 0 0 0 .71-.29l2-2A1 1 0 0 0 21 17a1 1 0 0 0-.29-.71z"/></g></g></svg> Rastgele<span> Kelime</span></div></Link>
        <Link to={`/kelime/${word.next}`}><div className="navButton">Sonraki
<svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><g data-name="arrow-forward"><rect width="24" height="24" transform="rotate(-90 12 12)" opacity="0"/><path d="M5 13h11.86l-3.63 4.36a1 1 0 0 0 1.54 1.28l5-6a1.19 1.19 0 0 0 .09-.15c0-.05.05-.08.07-.13A1 1 0 0 0 20 12a1 1 0 0 0-.07-.36c0-.05-.05-.08-.07-.13a1.19 1.19 0 0 0-.09-.15l-5-6A1 1 0 0 0 14 5a1 1 0 0 0-.64.23 1 1 0 0 0-.13 1.41L16.86 11H5a1 1 0 0 0 0 2z"/></g></g></svg></div></Link>
      </div>

      <SEO
        title={`${titleStr} Kelime Kökeni, Kelimesinin Anlamı - Etimoloji`}
        description={`${titleStr} kelimesinin anlamı, kökeni ve etimolojik incelemesi. ${titleStr} kelimesinin geçtiği en eski kaynak ve kelime etimolojisi, ${titleStr} ne demek?`}
        meta={[
          {name: "canonical", content: `https://www.etimolojiturkce.com/kelime/${word.kelime}`}
        ]}
        canonical={`https://www.etimolojiturkce.com/kelime/${word.kelime}`}
        isAmp={true}
        />
    </Layout>
  )
}
export const pageQuery = graphql`
  query($siteId: String!, $searchString: String!) {
    word(siteId: { eq: $siteId }){
      siteId
      kelime
      kok
      tanim
      tarihce
      koken
      ekaciklama
      tdk
      telaffuz
      prev
      next
      random
   }
   similar: allWord(limit: 13, filter: {kelime: {glob: $searchString}}) {
      edges {
        node {
          kelime
        }
      }
    }
    similarDef: allWord(limit: 10, filter: {tanim: {glob: $searchString}}) {
       edges {
         node {
           kelime
         }
       }
     }
  }
`
