import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = () => {
  return (
    <>
      <div className="footer" style={{marginTop: 15}}>
        <div className="footerInner">
          <div className="source">
            <div style={{width: 88}}>
              <a rel="license" href="https://creativecommons.org/licenses/by-nc-sa/4.0/">
                <strong>CC 4.0</strong>
              </a>
            </div>
            <div style={{width: "calc(100% - 100px)"}}>
              <p>
                <b>© 2012 - 2020</b> derleme içerik <a target="_blank" href="https://creativecommons.org/licenses/by-nc-sa/4.0/">Creative Commons Lisansı</a> ile lisanslıdır. Buna göre site içeriğini ticari olmayan çalışmalarınız için kullanabilir ve kaynak gösterebilirsiniz.
              </p>
            </div>
          </div>
          <p className="subtext">Site içeriği Wiktionary, NişanyanSözlük, TRT Telaffuz, etymologeek ve Etymonline içeriğine ve çevirilerine dayanmaktadır.</p>
        </div>
      </div>
    </>
  )
}

export default Footer
