import { Link, navigate } from "gatsby"
import React, { useState, useRef } from "react"

const Header = () => {
  return (
    <header>
      <div className="header">
        <div className="headerCarrier">
          <div className="search">
            <a href="https://www.etimolojiturkce.com">
              <input type="text"
                id="searchBox"
                placeholder="Kelimelerin kökenini arayın..."
                className={"autofilled"}/>
              <svg style={{width: 20, height: 20, position: "absolute", top: 10, left: 15}} viewBox={"0 0 30 30"}>
                <g fill="#fff">
                  <path d="M13,25.93 C5.871,25.93 0.07,20.129 0.07,13 C0.07,5.871 5.871,0.07 13,0.07 C20.129,0.07 25.93,5.871 25.93,13 C25.93,20.129 20.129,25.93 13,25.93 L13,25.93 Z M13,1.93 C6.896,1.93 1.93,6.896 1.93,13 C1.93,19.104 6.896,24.07 13,24.07 C19.104,24.07 24.07,19.104 24.07,13 C24.07,6.896 19.104,1.93 13,1.93 L13,1.93 Z"/>
                  <polyline points="30.293 31.707 21.293 22.707 22.707 21.293 31.707 30.293 30.293 31.707"/>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div style={{height: 60}}></div>
    </header>
  )
}

export default Header
